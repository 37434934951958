import React from 'react';
import { graphql, Link } from 'gatsby';
import SEO from '../components/SEO';
import { MDXRenderer } from "gatsby-plugin-mdx";
import LocalizedLink from '../components/LocalizedLink';
import MdxLink from '../components/MdxLink';

const Services = props => {
  const resources = props.data.resources.edges;
  const resourcesGrouped = resources.reduce(function(rv, x) {
	(rv[x.node.frontmatter.category] = rv[x.node.frontmatter.category] || []).push(x);
	return rv;
  }, {});
  const { intro } = props.data;
  const introImageClasses = "intro-image " + (intro.frontmatter ? `${intro.frontmatter.intro_image_absolute && 'intro-image-absolute'} ${intro.frontmatter.intro_image_hide_on_mobile && 'intro-image-hide-mobile'}` : "");

  return (
    <>
      <SEO title={intro.frontmatter.title} />

      <div className="intro">
        <div className="container">
          <div className="row justify-content-start">
            <div className="col-12 col-md-7 col-lg-6 order-2 order-md-1">
              <MDXRenderer components={{ a: MdxLink }}>{ intro.body }</MDXRenderer>
            </div>
            {intro.frontmatter.intro_image && (
              <div className="col-12 col-md-5 col-lg-6 order-1 order-md-2 position-relative">
                <img alt={intro.frontmatter.title} className={introImageClasses} src={intro.frontmatter.intro_image} />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="container pb-6">
        <div className="row">
          {Object.keys(resourcesGrouped).map(category =>
            <div>
              <div class="col-12"><h1 class="resource-category">{category}</h1></div>
              {resourcesGrouped[category].map(edge => (
                <div key={edge.node.id} className="cold-md-5 col-12">
                  <div className="card service service-teaser">
                    <div className="card-content">
                      <h2>
                        {edge.node.frontmatter.title}
                      </h2>
                      <MDXRenderer components={{ a: MdxLink }}>{ edge.node.body }</MDXRenderer>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export const query = graphql`
  query ResourcesQuery($locale: String!) {
    resources: allMdx(
      filter: {
        fields: { locale: { eq: $locale } }
        fileAbsolutePath: { regex: "/resources\/.*/" }
      }
      sort: { fields: [frontmatter___category, frontmatter___title], order: ASC }
    ) {
      edges {
        node {
          id
          body
          fields {
            slug
          }
          frontmatter {
            title
            url
            category
          }
        }
      }
    }
    intro: mdx(
      fields: { locale: { eq: $locale } }
      fileAbsolutePath: {regex: "/content\/resources....mdx$/"}
    ) {
      body
      frontmatter {
        title
        intro_image
        intro_image_absolute
        intro_image_hide_on_mobile
      }
    }
  }
`;

export default Services;
